import { Controller } from "stimulus"
import { Modal } from "bootstrap";

export default class extends Controller {
  showEditModal(event){
    var editModal = new Modal( document.getElementById("commentModal"));
    editModal.show();
  }
  showNewModal(event){
    var newModal = new Modal( document.getElementById("commentModal"));
    newModal.show();
  }
}
