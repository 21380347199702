// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"
import {Sortable } from "sortablejs"

const statusUpdateUrl = "/jobs/:id/update-status"

export default class extends Controller {

  static targets = [ "source" ]

  connect() {
    
  }

  updateStatus(event){
    let job_id = this.data.get('job')
    let original_value = this.data.get('original');
    let data = new FormData()
    
    if(original_value != this.sourceTarget.value){
      data.append("status_id", this.sourceTarget.value )
      Rails.ajax({
        url: statusUpdateUrl.replace(":id", job_id),
        type: 'PATCH',
        data: data,
        async: false,
        success: (response) => {
          
        }
      })
    }
  }
}
